import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { OrgChartModule } from 'ng2-org-chart';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { GuardVerifyComponent } from './guard-verify.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    OrgChartModule,
    NgxSpinnerModule
  ],
  declarations: [GuardVerifyComponent],
  exports: [
    GuardVerifyComponent
  ],
  providers: [
    NgxSpinnerService
  ]
})
export class GuardVerifyModule { }
