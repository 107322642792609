import { Component, OnInit } from '@angular/core';
declare var jquery: any;
declare var $: any;
declare var Swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService, AgencyService } from 'src/app/shared';
import { TagInputModule } from 'ngx-chips';

@Component({
  selector: 'app-agency',
  templateUrl: './agency.component.html',
  styleUrls: ['./agency.component.css']
})
export class AgencyComponent implements OnInit {

  agencyList?: Array<any> = [];
  addForm :FormGroup;
  editForm :FormGroup;
  eventTypeJson = [];
  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private agencyService: AgencyService,
    private commonService: CommonService
  ) {
    TagInputModule.withDefaults({
      tagInput: {
          placeholder: 'Enter a new condition',
          // add here other default values for tag-input
      },
      dropdown: {
          displayBy: 'my-display-value',
          // add here other default values for tag-input-dropdown
      }
    });

    this.addForm =fb.group({
      'name' :['',Validators.required],
      'code':['',Validators.required],
      'username':['',Validators.required],
      'password':['',Validators.required],
      'event_type_json':['',Validators.required],
      'note':[''],
      'address_desc':[''],
      'mobile':[''],
      'email':[''],
    });

    this.editForm = fb.group({
      'id' :['',Validators.required],
      'name' :['',Validators.required],
      'code':['',Validators.required],
      'username':['',Validators.required],
      'password':['',Validators.required],
      'event_type_json':['',Validators.required],
      'note':[''],
      'address_desc':[''],
      'mobile':[''],
      'email':[''],
    });

  }

  ngOnInit() {
     this.search();
  }

  search(){
    this.spinner.show();
    this.agencyList = []
    $('#agency_table').DataTable().clear().destroy();
    this.agencyService.get().subscribe(data => {
        this.agencyList = data;
        console.log(this.agencyList)
        setTimeout(()=>{
          $('#agency_table').DataTable({});
        },500);
        this.spinner.hide();
    }, error => {
      this.failDialog('');
      this.spinner.hide();
      console.error(error);
    });
  }

  submitted_add = false;
  openModelAdd()
  {
    this.submitted_add = false;
    this.eventTypeJson = [];
    this.addForm.patchValue({
      name: '',
      code: '',
      username: '',
      password: '',
      event_type_json: '',
      note: '',
      address_desc: '',
      mobile: '',
      email: '',
    });
    $('#modal-add-agency').modal('show');
  }

  submit(){
    console.log(this.eventTypeJson);
    this.submitted_add = true;

    if(this.eventTypeJson.length > 0){
      var eventTypeJson = [];
      this.eventTypeJson.forEach(data => {
        eventTypeJson.push(data.value);
      });
      this.addForm.patchValue({
        event_type_json: JSON.stringify(eventTypeJson),
      });
    }else{
      this.addForm.patchValue({
        event_type_json: '',
      });
    }

    console.log(this.addForm.value);
    if(this.addForm.invalid){
      return;
    }
    this.spinner.show();
    this.agencyService.create(this.addForm.value).subscribe(resp => {
      console.log(resp)
      $('#modal-add-agency').modal('hide');
      this.spinner.hide();
      this.search()
    }, err => {
      this.spinner.hide();
      this.failDialog('')
    });
  }

  submitted_edit = false;
  openDialogEdit(id){
    this.submitted_edit = false;
    this.eventTypeJson = [];
    this.spinner.show();
    this.agencyService.getById(id).subscribe(data => {
      console.log(data);
      var eventTypeJson = JSON.parse(data.event_type_json);
      if(eventTypeJson && eventTypeJson.length > 0){
        eventTypeJson.forEach(data => {
          var eventType = {
            display:data,
            value:data
          }
          this.eventTypeJson.push(eventType)
        });
      }
      this.editForm.patchValue({
        id: data.id,
        name: data.name,
        code: data.code,
        username: data.username,
        password: data.password,
        note: data.note,
        event_type_json: data.event_type_json,
        address_desc: data.address_desc,
        mobile: data.mobile,
        email: data.email,
      });
      $('#modal-edit-agency').modal('show');
      this.spinner.hide();
    }, error => {
      this.failDialog('');
      this.spinner.hide();
      console.error(error);
    });
  }

  update(){
    console.log(this.eventTypeJson);
    this.submitted_edit = true;

    if(this.eventTypeJson.length > 0){
      var eventTypeJson = [];
      this.eventTypeJson.forEach(data => {
        eventTypeJson.push(data.value);
      });
      this.editForm.patchValue({
        event_type_json: JSON.stringify(eventTypeJson),
      });
    }else{
      this.editForm.patchValue({
        event_type_json: '',
      });
    }

    console.log(this.editForm.value);
    if(this.editForm.invalid){
      return;
    }
    this.spinner.show();
    this.agencyService.update(this.editForm.value,this.editForm.value.id).subscribe(resp => {
      console.log(resp)
      $('#modal-edit-agency').modal('hide');
      this.spinner.hide();
      this.search()
    }, err => {
      this.spinner.hide();
      this.failDialog('')
    });
  }

  openDialogDelete(id){
    Swal.fire({
      title: 'คุณต้องการลบข้อมูลนี้ใช่ไหม',
      text: "กดยืนยันเพื่อลบข้อมูลนี้",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      console.log(result)
      if (result.value) {
        this.spinner.show()
        this.agencyService.delete(id).subscribe(resp => {
          this.spinner.hide()
          this.search()
        }, err => {
          this.spinner.hide()
          this.failDialog('')
          console.log("=== err ===")
          console.log(err)
        });

      }
    })
  }

  successDialog() {
    Swal.fire("ทำรายการสำเร็จ!", "", "success");
  }

  failDialog(msg) {
    Swal.fire({
      type: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: msg
    })
  }

}
