import { Component, OnInit } from '@angular/core';
declare var jquery: any;
declare var $: any;
declare var Swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { CodeRedService, CommonService } from 'src/app/shared';
import { Device, DeviceSummaryInfo } from 'src/app/shared/model/model';

@Component({
  selector: 'app-guard-verify',
  templateUrl: './guard-verify.component.html',
  styleUrls: ['./guard-verify.component.css']
})
export class GuardVerifyComponent implements OnInit {


  guardList?: Array<any> = []; 

  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private codeRedService: CodeRedService
  ) {

  }

  firstName
  lastName
  identityNo
  customerId
  ngOnInit() {
    this.customerId = localStorage.getItem("customer_id");
    this.search();
  }

  search(){
    this.spinner.show();
    $("#guard_table").DataTable().clear().destroy();
    var req = {
      first_name:this.firstName,
      last_name:this.lastName,
      identity_no:this.identityNo
    };
    this.guardList = [];
    this.codeRedService.search(req).subscribe(res=>{
      this.guardList = res;
      setTimeout(() => {
        this.spinner.hide();
        $('#guard_table').DataTable({
        });
      }, 100);
    }, error => {
      this.spinner.hide();
      console.error(error);
      this.failDialog('');
    });
  }

  currentId;
  delete(id){
    this.currentId = id;
    $('#modal-remove').modal('show');
  }

  deleteProcess(){
    this.spinner.show();
    console.log(this.currentId);
    this.codeRedService.delete(this.currentId).subscribe(data => {
      this.spinner.hide();
      $('#modal-remove').modal('hide');
      this.successDialog();
      this.search();
    }, error => {
      this.spinner.hide();
      console.error(error);
      this.failDialog('');
    });
  }

  successDialog() {
    this.spinner.hide();
    Swal.fire("ทำรายการสำเร็จ!", "", "success");
  }

  failDialog(msg) { 
    this.spinner.hide();
    Swal.fire({
      type: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: msg
    })
  }

}
