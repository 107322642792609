import { Component, Directive, Input, OnInit } from "@angular/core";
declare var jquery: any;
declare var $: any;
declare var Swal: any;
import { NgxSpinnerService } from "ngx-spinner";
import { SurveyRoundService } from "../shared/service/survey-round.service";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { AgencyService } from "../shared";

@Directive({
  selector: "[var]",
  exportAs: "var",
})
class VarDirective {
  @Input() var: any;
}

@Component({
  selector: "app-survey-round",
  templateUrl: "./survey-round.component.html",
  styleUrls: ["./survey-round.component.css"],
})
export class SurveyRoundComponent implements OnInit {
  suveyRoundLs?: Array<any> = [];
  agencyList?: Array<any> = [];
  surveyRoundItem?: Array<any> = [];

  pointList?: Array<any> = [];
  deviceList?: Array<any> = [];

  addSurveyForm: FormGroup;
  editSurveyForm: FormGroup;
  submitted_add = false;
  submitted_edit = false;

  surveyData: any;

  constructor(
    private spinner: NgxSpinnerService,
    private surveyRoundService: SurveyRoundService,
    private fb: FormBuilder,
    private agencyService: AgencyService
  ) {
    this.addSurveyForm = fb.group({
      name: ["", Validators.required],
      code: ["", Validators.required],
      agency_id: ["", Validators.required],
      round_item: this.fb.array([]),
    });

    this.editSurveyForm = fb.group({
      eid: [""],
      name: ["", Validators.required],
      code: ["", Validators.required],
      agency_id: ["", Validators.required],
      round_item: this.fb.array([]),
    });
  }

  ngOnInit() {
    this.getAgency();
    this.search();
  }

  search() {
    this.spinner.show();
    this.suveyRoundLs = [];
    $("#survey_table").DataTable().clear().destroy();
    this.surveyRoundService.get().subscribe(
      (data) => {
        this.suveyRoundLs = data;
        this.suveyRoundLs.forEach((element) => {
          element.point = element.point.split("||");
          element.device = element.device.split("||");
        });
        setTimeout(() => {
          $("#survey_table").DataTable({});
        }, 500);
        this.spinner.hide();
      },
      (error) => {
        this.failDialog("");
        this.spinner.hide();
        console.error(error);
      }
    );
  }

  getAgency() {
    this.agencyService.get().subscribe((res) => {
      this.agencyList = res;
    });
  }

  openDialogDelete(id: any) {
    Swal.fire({
      title: "คุณต้องการลบข้อมูลนี้ใช่ไหม",
      text: "กดยืนยันเพื่อลบข้อมูลนี้",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.surveyRoundService.delete(id).subscribe(
          (resp) => {
            this.spinner.hide();
            this.search();
          },
          (err) => {
            this.spinner.hide();
            this.failDialog("");
            console.log("=== err ===");
            console.log(err);
          }
        );
      }
    });
  }

  changeAgency(agencyId: any) {
    if (agencyId === "") {
      this.pointList = [];
      this.deviceList = [];
      return;
    }

    this.spinner.show();
    this.surveyRoundService.getPointByAgencyId(agencyId).subscribe((res) => {
      //this.spinner.hide();
      this.pointList = res;
    }),
      (err) => {
        //this.spinner.hide();
        console.log(err);
      };

    this.surveyRoundService.getDeviceByAgencyId(agencyId).subscribe((res) => {
      this.spinner.hide();
      this.deviceList = res;
    }),
      (err) => {
        this.spinner.hide();
        console.log(err);
      };
  }

  get addDynamicElementAdd() {
    return this.addSurveyForm.get("round_item") as FormArray;
  }

  get addDynamicElementEdit() {
    return this.editSurveyForm.get("round_item") as FormArray;
  }

  roundItemForm(): FormGroup {
    return this.fb.group({
      start_time: ["00:00"],
      end_time: ["00:00"],
    });
  }

  addRoundItemEdit() {
    this.addDynamicElementEdit.push(this.roundItemForm());
  }

  roundItemFormVale(t: any, e: any): FormGroup {
    return this.fb.group({
      start_time: [t],
      end_time: [e],
    });
  }

  addRoundItem() {
    this.addDynamicElementAdd.push(this.roundItemForm());
  }

  removeRoundItem(index: any) {
    this.addDynamicElementAdd.removeAt(index);
  }
  removeRoundItemEdit(index: any) {
    this.addDynamicElementEdit.removeAt(index);
  }

  timeFocusOut(value: any, index: any, type: any) {
    if (value === "") return;
    let rx = new RegExp("^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$");
    if (!rx.test(value)) {
      this.toast(
        "รอบตรวจที่ " +
          (index + 1) +
          " รูปแบบเวลา" +
          type +
          "ไม่ถูกต้อง (HH:mm) 24hr ตัวอย่าง 23:59"
      );
    }
  }

  create() {
    this.submitted_add = true;
    if (this.addSurveyForm.invalid) {
      return;
    }

    let data = this.addSurveyForm.value;
    let array = data.round_item;
    console.log(array);

     //check time
     let rx = new RegExp("^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$");
     let check = true;
     let lastEndTime = "";

     array.forEach((element, index) => {
       if (element.start_time === "" && check) {
         this.warningDialog(
           "รอบตรวจที่ " + (index + 1) + " กรุณาระบุเวลาเริ่มต้น "
         );
         check = false;
       }

       if (element.end_time === "" && check) {
         this.warningDialog(
           "รอบตรวจที่ " + (index + 1) + " กรุณาระบุเวลาสิ้นสุด "
         );
         check = false;
       }

       if (!rx.test(element.start_time) && check) {
         this.warningDialog(
           "รอบตรวจที่ " +
             (index + 1) +
             " รูปแบบเวลาเริ่มต้นไม่ถูกต้อง (HH:mm) 24hr ตัวอย่าง 23:59 "
         );
         check = false;
       }

       if (!rx.test(element.end_time) && check) {
         this.warningDialog(
           "รอบตรวจที่ " +
             (index + 1) +
             " รูปแบบเวลาสิ้นสุดไม่ถูกต้อง (HH:mm) 24hr ตัวอย่าง 23:59 "
         );
         check = false;
       }

       if (check) {
         //check end less then start
         let date1 = new Date(`2000-01-01T${element.start_time}Z`);
         let date2 = new Date(`2000-01-01T${element.end_time}Z`);
         if (date2 <= date1 && check) {
           this.warningDialog(
             "รอบตรวจที่ " +
               (index + 1) +
               " กรุณากรอกเวลาสิ้นสุดมากกว่าเวลาเริ่มต้น "
           );
           check = false;
         }

         //check current start time more then last time forward
         if (index > 0 && check) {
           console.log(
             `check last date ${index} | ${element.start_time}   ${lastEndTime}`
           );
           date1 = new Date(`2000-01-01T${lastEndTime}Z`);
           date2 = new Date(`2000-01-01T${element.start_time}Z`);
           if (date2 <= date1) {
             this.warningDialog(
               "รอบตรวจที่ " +
                 (index + 1) +
                 " กรุณากรอกเวลาเริ่มต้นมากกว่าเวลาสิ้นสุดรอบก่อนหน้า "
             );

             check = false;
           }
         }
         lastEndTime = element.end_time;
       }
     });

    if (check) {
      this.spinner.show();
      this.surveyRoundService.create(this.addSurveyForm.value).subscribe(
        (resp) => {
          console.log(resp);
          $("#modal-create-survey-round").modal("hide");
          this.spinner.hide();
          this.search();
        },
        (err) => {
          this.spinner.hide();
          console.log(err);
          try {
            let emsg = err.error.data.error_message;
            this.failDialog(emsg);
          } catch (e) {
            this.failDialog("");
          }
        }
      );
    }
  }

  update() {
    this.submitted_edit = true;
    if (this.editSurveyForm.invalid) {
      return;
    }
    // console.log(this.editSurveyForm.value);

    let data = this.editSurveyForm.value;
    let array = data.round_item;
    console.log(array);

    //check time
    let rx = new RegExp("^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$");
    let check = true;
    let lastEndTime = "";

    array.forEach((element, index) => {
      if (element.start_time === "" && check) {
        this.warningDialog(
          "รอบตรวจที่ " + (index + 1) + " กรุณาระบุเวลาเริ่มต้น "
        );
        check = false;
      }

      if (element.end_time === "" && check) {
        this.warningDialog(
          "รอบตรวจที่ " + (index + 1) + " กรุณาระบุเวลาสิ้นสุด "
        );
        check = false;
      }

      if (!rx.test(element.start_time) && check) {
        this.warningDialog(
          "รอบตรวจที่ " +
            (index + 1) +
            " รูปแบบเวลาเริ่มต้นไม่ถูกต้อง (HH:mm) 24hr ตัวอย่าง 23:59 "
        );
        check = false;
      }

      if (!rx.test(element.end_time) && check) {
        this.warningDialog(
          "รอบตรวจที่ " +
            (index + 1) +
            " รูปแบบเวลาสิ้นสุดไม่ถูกต้อง (HH:mm) 24hr ตัวอย่าง 23:59 "
        );
        check = false;
      }

      if (check) {
        //check end less then start
        let date1 = new Date(`2000-01-01T${element.start_time}Z`);
        let date2 = new Date(`2000-01-01T${element.end_time}Z`);
        if (date2 <= date1 && check) {
          this.warningDialog(
            "รอบตรวจที่ " +
              (index + 1) +
              " กรุณากรอกเวลาสิ้นสุดมากกว่าเวลาเริ่มต้น "
          );
          check = false;
        }

        //check current start time more then last time forward
        if (index > 0 && check) {
          console.log(
            `check last date ${index} | ${element.start_time}   ${lastEndTime}`
          );
          date1 = new Date(`2000-01-01T${lastEndTime}Z`);
          date2 = new Date(`2000-01-01T${element.start_time}Z`);
          if (date2 <= date1) {
            this.warningDialog(
              "รอบตรวจที่ " +
                (index + 1) +
                " กรุณากรอกเวลาเริ่มต้นมากกว่าเวลาสิ้นสุดรอบก่อนหน้า "
            );

            check = false;
          }
        }
        lastEndTime = element.end_time;
      }
    });

    if (check) {
      this.spinner.show();
      this.surveyRoundService
        .update(this.editSurveyForm.value, this.editSurveyForm.value.eid)
        .subscribe(
          (resp) => {
            console.log(resp);
            $("#modal-survey-edit").modal("hide");
            this.spinner.hide();
            this.search();
          },
          (err) => {
            this.spinner.hide();
            console.log(err);
            try {
              let emsg = err.error.data.error_message;
              this.failDialog(emsg);
            } catch (e) {
              console.log(e);
              this.failDialog("");
            }
          }
        );
    }
  }

  openModelCreate() {
    this.submitted_add = false;
    this.addSurveyForm.patchValue({
      code: "",
      name: "",
      agency_id: "",
      round_item: [],
    });
    this.addDynamicElementAdd.clear();
    this.pointList = [];
    this.deviceList = [];
    $("#modal-create-survey-round").modal("show");
  }

  successDialog() {
    Swal.fire("ทำรายการสำเร็จ!", "", "success");
  }

  failDialog(msg) {
    Swal.fire({
      type: "error",
      title: "เกิดข้อผิดพลาด",
      text: msg,
    });
  }

  warningDialog(msg: any) {
    Swal.fire({
      type: "warning",
      title: "",
      text: msg,
    });
  }

  openDialogEdit(id: any) {
    this.submitted_edit = false;
    this.spinner.show();
    this.surveyRoundService.getByid(id).subscribe(
      (res) => {
        this.surveyData = res;

        this.submitted_edit = false;
        this.editSurveyForm.patchValue({
          eid: this.surveyData.id,
          name: this.surveyData.name,
          code: this.surveyData.code,
          agency_id: this.surveyData.agency_id,
          round_item_edit: [],
        });
        this.changeAgency(this.surveyData.agency_id);

        let roundItem = this.surveyData.round_item;
        this.addDynamicElementEdit.clear();
        roundItem.forEach((element) => {
          this.addDynamicElementEdit.push(
            this.roundItemFormVale(element.start_time, element.end_time)
          );
        });
        this.spinner.hide();
        $("#modal-survey-edit").modal("show");
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }

  toast(message: string, typeIcon = "error") {
    Swal.fire({
      toast: true,
      position: "top",
      showConfirmButton: false,
      type: typeIcon,
      timer: 10000,
      title: "<div class='text-danger'><div>" + message + "</div></div>",
      customClass: {},
    });
  }
}
