import { Component, OnInit } from '@angular/core';
declare var jquery: any;
declare var $: any;
declare var Swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CodeRedHistoryService, CodeRedService, CommonService } from 'src/app/shared';
import { Device, DeviceSummaryInfo } from 'src/app/shared/model/model';
import { DeviceService } from 'src/app/shared/service/device.service';

@Component({
  selector: 'app-guard-detail-approve',
  templateUrl: './guard-detail.component.html',
  styleUrls: ['./guard-detail.component.css']
})
export class GuardDetailApproveComponent implements OnInit {

  searchForm: FormGroup;
  addForm: FormGroup;
  editForm: FormGroup;
  submitted_add = false;
  submitted_edit = false;
  submitted_search = false;

  //Device Model
  deviceId: Number;
  agencyList?: Array<any> = [];
  deviceList?: Array<any> = []; 
  isDuplicateImei: Boolean = false;
  deviceSummaryInfo: DeviceSummaryInfo = {limit:0, active:0, inactive:0};
  isSelectAll: Boolean = false;
  id;
  codeRedId;
  codeRed;
  codeRedHistory;

  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private router: Router,
    private codeRedService: CodeRedService,
    private codeRedHistoryService: CodeRedHistoryService
  ) {
    this.searchForm = fb.group({
       
    });
    this.addForm = fb.group({
      'agency_id': ['', Validators.required],
      'name': ['', Validators.required],
      'imei': ['', Validators.required],
      'description': [''],
      'is_active': [true]
    });
    this.editForm = fb.group({
      'id': ['', Validators.required],
      'agency_id': ['', Validators.required],
      'name': ['', Validators.required],
      'imei': ['', Validators.required],
      'description': [''],
      'is_active': [true]
    });
  }

  ngOnInit() {
    this.activatedRoute.params.forEach((urlParams) => {
      this.id = urlParams['id'].replace('#', '');
      console.log("id : "+this.id); 
      this.spinner.show();
      this.getCodeRedHistory();
    });
  }

  getCodeRedHistory(){
    this.codeRedHistoryService.getById(this.id).subscribe(res=>{
      console.log(res);
      this.codeRedHistory = res;
      this.codeRedId = res.code_red_id;
      this.getCodeRed();
    }, error => {
      this.spinner.hide();
      console.error(error);
      this.failDialog('');
    });
  }

  getCodeRed(){
    this.codeRedService.getById(this.codeRedId).subscribe(res=>{
      console.log(res);
      this.codeRed = res;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      console.error(error);
      this.failDialog('');
    });
  }

  data = {}
  openDetail(data){
    this.data = data;
    $('#modal-detail').modal('show');
  }

  openDialogApprove(id){
    Swal.fire({
      title: 'ยืนยันอนุมัติ',
      text: "กดปุ่มยืนยันเพื่ออนุมัติ",
      type: 'warning',
      showCancelButton: true,
      // confirmButtonColor: '#3085d6',
      // cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ปิด'
    }).then((result) => {
      console.log(result)
      if (result.value) {
        this.spinner.show()
        var req = {
          "status":"AP"
        }
        this.codeRedHistoryService.updateStatus(req,id).subscribe(resp => {
          this.spinner.hide();
          $('#modal-detail').modal('hide');
          this.successDialog();
          setTimeout(() => {
            this.router.navigate(['/guard/approve']);
          }, 1000);
        }, err => {
          this.spinner.hide()
          this.failDialog('')
          console.log("=== err ===")
          console.log(err)
        });

      }
    })
  }

  openDialogNotApprove(id){
    $('#modal-detail').modal('hide');
    Swal.fire({
      title: 'หมายเหตุในการไม่อนุมัติ',
      input: 'text',
      showCancelButton: true,
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ปิด',
      showLoaderOnConfirm: true,
      preConfirm: (text) => {
        if(!text){
          Swal.showValidationMessage(
            `หมายเหตุในการไม่อนุมัติ`
          )
          return
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      console.log(result)
      if (result.value) {
        this.spinner.show();
        var req = {
          "note":result.value,
          "status":"NAP"
        }
        this.codeRedHistoryService.updateStatus(req,id).subscribe(resp => {
          console.log(resp)
          this.spinner.hide();
          this.successDialog();
          setTimeout(() => {
            this.router.navigate(['/guard/approve']);
          }, 1000);
        }, err => {
          this.spinner.hide();
          this.failDialog('')
          console.log("=== err ===")
          console.log(err)
        });
      }
    })
  }

  successDialog() {
    this.spinner.hide();
    Swal.fire("ทำรายการสำเร็จ!", "", "success");
  }

  failDialog(msg) { 
    this.spinner.hide();
    Swal.fire({
      type: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: msg
    })
  }

}
