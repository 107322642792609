import { Component } from "@angular/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  access_token = null;

  ngOnInit() {
    this.access_token = localStorage.getItem('access_token');
    if(!this.access_token){
      localStorage.clear();
      return;
    }
  }
}
