import { SurveyRoundModule } from "./survey-round/survey-round.model";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BasicAuthInterceptor, ErrorInterceptor } from './_helpers';
import {
  HeaderComponent,
  MenuLeftComponent,
  LoginInitComponent,
  FooterComponent,
  Constant,
} from "./shared";
import { DashboardModule } from "./dashboard/dashboard.module";
import { AgencyModule } from "./setting/agency/agency.module";
import { DeviceListModule } from "./setting/device/list/device-list.module";
import { PointCardModule } from "./setting/point/point.module";
import { GuardVerifyModule } from "./guard/verify/guard-verify.module";
import { GuardDetailModule } from "./guard/detail/guard-detail.module";
import { GuardDetailApproveModule } from "./guard/detail-approve/guard-detail.module";
import { GuardCreateModule } from "./guard/create/guard-create.module";
import { GuardApproveModule } from "./guard/approve/guard-approve.module";
import { GuardVerifyRegistrationModule } from "./guard/verify-registration/guard-verify-registration.module";
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ResetPasswordModule } from './reset-password/reset-password.module';
import { GuardEditApproveModule } from "./guard/edit/guard-edit.module";
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuLeftComponent,
    FooterComponent,
    LoginInitComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    DashboardModule,
    DeviceListModule,
    PointCardModule,
    SurveyRoundModule,
    AgencyModule,
    GuardVerifyModule,
    GuardDetailModule,
    GuardDetailApproveModule,
    GuardCreateModule,
    GuardApproveModule,
    GuardVerifyRegistrationModule,
    ResetPasswordModule,
    FormsModule,
    ReactiveFormsModule,
    GuardEditApproveModule,
    NgxSpinnerModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    Constant,
    NgxSpinnerService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
