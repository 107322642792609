import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
declare var jquery: any;
declare var $: any;
declare var Swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { CodeRedService, CommonService } from 'src/app/shared';

@Component({
  selector: 'app-guard-create',
  templateUrl: './guard-create.component.html',
  styleUrls: ['./guard-create.component.css']
})
export class GuardCreateComponent implements OnInit {

  searchForm: FormGroup;
  addForm: FormGroup;
  editForm: FormGroup;
  submitted_add = false;
  submitted_edit = false;
  submitted_search = false;

  //Device Model
  deviceId: Number;
  agencyList?: Array<any> = [];
  deviceList?: Array<any> = []; 
  isDuplicateImei: Boolean = false;
  isSelectAll: Boolean = false;

  optionleDate: any = {
    locale: { format: 'DD-MM-YYYY' },
    alwaysShowCalendars: false,
    singleDatePicker: true
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private codeRedService: CodeRedService
  ) {
    this.searchForm = fb.group({
       
    });
    this.addForm = fb.group({
      'first_name': ['', Validators.required],
      'last_name': ['', Validators.required],
      'identity_no': ['', Validators.required],
      'mobile': [''],
      'email': [''],
      'address_detail': [''],
      'work_history': [''],
      'note': [''],
      'date_incident': [''],
      'code_red_name': ['', Validators.required],
      'informant_name': ['', Validators.required],
      'location': ['', Validators.required],
      'incidence': ['', Validators.required],
      'code_red_id': ['0'],
      'user_id': [''],
      'customer_id': [''],
    });
    this.editForm = fb.group({
      'id': ['', Validators.required],
      'agency_id': ['', Validators.required],
      'name': ['', Validators.required],
      'imei': ['', Validators.required],
      'description': [''],
      'is_active': [true]
    });
  }

  ngOnInit() {
    var userFirstName = localStorage.getItem("user_first_name");
    var userLastName = localStorage.getItem("user_last_name");
    var userId = localStorage.getItem("user_id");
    var customerId = localStorage.getItem("customer_id");

    this.addForm.patchValue({
      date_incident: this.dateIncident,
      informant_name: userFirstName+" "+userLastName,
      user_id: userId,
      customer_id: customerId,
    });
     //Initial
     //this.getDeviceSummaryInfo();
     //this.getAgency();
     //this.search();
  }

  clearData(){
    this.addForm.patchValue({
      code_red_id: "",
      first_name: "",
      last_name: "",
      identity_no: "",
      mobile: "",
      email: "",
      address_detail: "",
      code_red_name: "",
      location: "",
      incidence: "",
      user_id: "",
      customer_id: "",
    });
  }

  getExtension(filename) {
    var parts = filename.split('.');
    return parts[parts.length - 1];
  }

  isPdfAndImage(filename) {
    var ext = this.getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'jpg':
      case 'jpeg':
      case 'png': 
        return true;
    }
    return false;
  }

  selectedFile0 = []
  selectedFile1 = []
  selectedFile2 = []
  selectedFile3 = []
  selectedFile4 = []
  selectedFile5 = []
  selectedFile6 = []
  selectFile(event, type) {
    if(type === 0){
      this.selectedFile0 = event.target.files;
      var file = event.target.files[0];
      if(!this.isPdfAndImage(file.name)){
        this.warningDialog('รองรับไฟล์รูปภาพเท่านั้น jpg,jpeg,png');
        this.selectedFile0 = null;
        return;
      }
      if (file) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.imageUrl = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    }else if(type === 1){
      this.selectedFile1 = event.target.files;
    }else if(type === 2){
      this.selectedFile2 = event.target.files;
    }else if(type === 3){
      this.selectedFile3 = event.target.files;
    }else if(type === 4){
      this.selectedFile4 = event.target.files;
    }else if(type === 5){
      this.selectedFile5 = event.target.files;
    }else if(type === 6){
      this.selectedFile6 = event.target.files;
    }
    console.log(this.selectedFile0);
    console.log(this.selectedFile1);
    console.log(this.selectedFile2);
    console.log(this.selectedFile3);
    console.log(this.selectedFile4);
    console.log(this.selectedFile5);
    console.log(this.selectedFile6);
  }

  submitCodeRed(){
    this.submitted_add = true;
    console.log(this.addForm.value);
    if(this.addForm.invalid){
      return;
    }

    var file0 = new File([""], "");
    if(this.selectedFile0.length > 0){
      file0 = this.selectedFile0[0];
    }
    var file1 = new File([""], "");
    if(this.selectedFile1.length > 0){
      file1 = this.selectedFile1[0];
    }
    var file2 = new File([""], "");
    if(this.selectedFile2.length > 0){
      file2 = this.selectedFile2[0];
    }
    var file3 = new File([""], "");
    if(this.selectedFile3.length > 0){
      file3 = this.selectedFile3[0];
    }
    var file4 = new File([""], "");
    if(this.selectedFile4.length > 0){
      file4 = this.selectedFile4[0];
    }
    var file5 = new File([""], "");
    if(this.selectedFile5.length > 0){
      file5 = this.selectedFile5[0];
    }
    var file6 = new File([""], "");
    if(this.selectedFile6.length > 0){
      file6 = this.selectedFile6[0];
    }

    var formData: FormData = new FormData();
    formData.append('file0', file0);
    formData.append('file1', file1);
    formData.append('file2', file2);
    formData.append('file3', file3);
    formData.append('file4', file4);
    formData.append('file5', file5);
    formData.append('file6', file6);
    formData.append('codeRedId', "0");
    formData.append('firstName', this.addForm.value.first_name);
    formData.append('lastName', this.addForm.value.last_name);
    formData.append('identityNo', this.addForm.value.identity_no);
    formData.append('mobile', this.addForm.value.mobile);
    formData.append('email', this.addForm.value.email);
    formData.append('addressDetail', this.addForm.value.address_detail);
    formData.append('workHistory', this.addForm.value.work_history);
    formData.append('note', this.addForm.value.note);
    formData.append('dateIncident', this.addForm.value.date_incident);
    formData.append('codeRedName', this.addForm.value.code_red_name);
    formData.append('informantName', this.addForm.value.informant_name);
    formData.append('location', this.addForm.value.location);
    formData.append('incidence', this.addForm.value.incidence);
    formData.append('userId', this.addForm.value.user_id);
    formData.append('customerId', this.addForm.value.customer_id);

    this.spinner.show();
    this.codeRedService.create(formData).subscribe(data=>{
      console.log(data);
      this.spinner.hide();
      if(data.type == 1){
        this.successDialog();
        setTimeout(() => {
          this.router.navigate(['/guard/verify']);
        }, 1000);
      }
    }, error => {
      this.spinner.hide();
      console.error(error);
      this.failDialog(error);
    });
  }

  setInformantName(event: any){
    this.addForm.patchValue({
      code_red_name: this.addForm.value.first_name+" "+this.addForm.value.last_name,
    });
  }

  imageUrl
  isSearchIdentityNo = false;
  isDisabledInfo = false;
  searchIdentityNo(){
    this.imageUrl = null;
    this.spinner.show();
    this.codeRedService.getByIdentityNo(this.addForm.value.identity_no).subscribe(data => {
      console.log(data)
      if(data.id){
        this.isDisabledInfo = true;
        this.imageUrl = data.image_url;
        this.addForm.patchValue({
          code_red_id: data.id,
          first_name: data.first_name,
          last_name: data.last_name,
          mobile: data.mobile,
          email: data.email,
          address_detail: data.address_detail,
          note: data.note,
          work_history: data.work_history,
          code_red_name: data.first_name+" "+data.last_name,
        });

        this.addForm.controls.first_name.disable();
        this.addForm.controls.last_name.disable();
        this.addForm.controls.mobile.disable();
        this.addForm.controls.email.disable();
        this.addForm.controls.address_detail.disable();
        this.addForm.controls.note.disable();
        this.addForm.controls.work_history.disable();
      }else{
        this.isDisabledInfo = false;
        this.addForm.patchValue({
          code_red_id: "",
          first_name: "",
          last_name: "",
          mobile: "",
          email: "",
          address_detail: "",
          note: "",
          work_history: "",
          code_red_name: "",
        });

        this.addForm.controls.first_name.enable();
        this.addForm.controls.last_name.enable();
        this.addForm.controls.mobile.enable();
        this.addForm.controls.email.enable();
        this.addForm.controls.address_detail.enable();
        this.addForm.controls.note.enable();
        this.addForm.controls.work_history.enable();
      }
      this.isSearchIdentityNo = true;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      console.error(error);
      this.failDialog('');
    });
  }

  dateIncident = this.getDateStr(new Date());
  selectedDateIncident(value: any, datepicker?: any) {
    console.log(value);
    this.dateIncident = this.getDateStr(value.end._d);
    console.log(this.dateIncident);
    this.addForm.patchValue({
      date_incident: this.dateIncident,
    });
  }

  getDateStr(date){
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();
    return dd + '-' + mm + '-' + yyyy;
  }

  successDialog() {
    Swal.fire("ทำรายการสำเร็จ!", "", "success");
  }

  warningDialog(msg){
    Swal.fire({
      type: 'warning',
      title: '',
      text: msg
    })
  }

  failDialog(msg) { 
    this.spinner.hide();
    Swal.fire({
      type: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: msg
    })
  }

}
