import { Component, OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service';
declare var jquery: any;
declare var $: any;
declare var Swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'login-init',
  templateUrl: './login.component.html'
})
export class LoginInitComponent implements OnInit {

  addForm: FormGroup;
  recoverForm: FormGroup;
  submitted_add = false;
  submitted_reset = false;
  loginform = true;
  recoverform = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
  ) {
    this.addForm = fb.group({
      'first_name': ['', Validators.required],
      'last_name': ['', Validators.required],
      'company_name': ['', Validators.required],
      'mobile': ['', Validators.required],
      'mobile_company': ['', Validators.required],
      'email': ['', Validators.required]
    });

    this.recoverForm = fb.group({
      'email': ['', Validators.required]
    });
  }

  ngOnInit() {
  } 

  authen(e){
    this.spinner.show();
    e.preventDefault();
      var username = e.target.elements[0].value;
      var password = e.target.elements[1].value;
      var outsider = e.target.elements[2].checked;
      var param = {'username':username, 'password':password, 'outsider': outsider};
      console.log(param);
      this.authService.auth(param).subscribe(res=>{
        console.log(res.data);
        this.authService.setCustomer(res.data);
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      }, error => {
        this.spinner.hide();
        console.log(error);
        this.warningDialog("อีเมล หรือ รหัสผ่านไม่ถูกต้อง","");
      });
  }

  openRegister(){
    this.submitted_add = false;
    this.addForm.patchValue({
      first_name: "",
      last_name: "",
      company_name: "",
      mobile: "",
      mobile_company: "",
      email: "",
    });
    $('#modal-register').modal('show');
  }

  openForgotPassword(){
    this.recoverform = true;
    this.loginform = false;
  }

  openLogin(){
    this.recoverform = false;
    this.loginform = true;
  }

  submitRegister(){
    this.submitted_add = true;
    console.log(this.addForm.value);
    if(this.addForm.invalid){
      return;
    }
    this.spinner.show();
    this.authService.register(this.addForm.value).subscribe(res=>{
      console.log(res.data);
      setTimeout(() => {
        $('#modal-register').modal('hide');
        this.successDialog("ลงทะเบียนเรียบร้อย","เมื่อทำการตรวจสอบข้อมูลเรียบร้อย ทางระบบจะส่งรหัสผ่านไปทางอีเมล");
        this.spinner.hide();
      }, 500);
    }, error => {
      this.spinner.hide();
      console.log(error);
      if(error.error.code = 'duplicate_data'){
        this.warningDialog("ลงทะเบียนไม่สำเร็จ","อีเมลนี้ถูกลงทะเบียนแล้ว");
      }else{
        this.failDialog("เกิดข้อผิดพลาด","กรุณาลองใหม่อีกครั้ง");
      }
    });
  }

  submitForgotPassword(){
    this.submitted_reset = true;
    console.log(this.recoverForm.value);
    if(this.recoverForm.invalid){
      return;
    }
    this.spinner.show();
    this.authService.forgotPassword(this.recoverForm.value).subscribe(res=>{
      console.log(res.data);
      setTimeout(() => {
        this.openLogin();
        this.successDialog("กู้คืนรหัสผ่านเรียบร้อย","ระบบได้ส่งรหัสผ่านใหม่ในเมลเรียบร้อย");
        this.spinner.hide();
      }, 500);
    }, error => {
      this.spinner.hide();
      console.log(error);
      this.warningDialog("กู้คืนรหัสผ่านไม่สำเร็จ","อีเมลนี้ไม่มีในระบบ");
    });
  }

  successDialog(title,msg) {
    Swal.fire(title, msg, "success");
  }

  failDialog(title,msg){
    Swal.fire({
      type: 'error',
      title: title,
      text: msg
    })
  }

  warningDialog(title,msg){
    Swal.fire({
      type: 'warning',
      title: title,
      text: msg
    })
  }
}
