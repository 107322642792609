import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { DeviceListComponent } from "./setting/device/list/device-list.component";
import { PointCardComponent } from "./setting/point/point.component";
import { SurveyRoundComponent } from "./survey-round/survey-round.component";
import { AgencyComponent } from "./setting/agency/agency.component";
import { AuthGuard } from "./_helpers/auth.guard";
import { GuardVerifyComponent } from "./guard/verify/guard-verify.component";
import { GuardDetailComponent } from "./guard/detail/guard-detail.component";
import { GuardDetailApproveComponent } from "./guard/detail-approve/guard-detail.component";
import { GuardCreateComponent } from "./guard/create/guard-create.component";
import { GuardApproveComponent } from "./guard/approve/guard-approve.component";
import { GuardVerifyRegistrationComponent } from "./guard/verify-registration/guard-verify-registration.component";
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { GuardEditApproveComponent } from "./guard/edit/guard-edit.component";

const routes: Routes = [
  {
    path: "",
    component: GuardVerifyComponent,
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: "setting/agency",
    component: AgencyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "setting/device",
    component: DeviceListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "setting/point",
    component: PointCardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "survey-round",
    component: SurveyRoundComponent,
    canActivate: [AuthGuard],
  },

  //Guard
  {
    path: "guard/verify",
    component: GuardVerifyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "guard/detail/:id",
    component: GuardDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "guard/detail-approve/:id",
    component: GuardDetailApproveComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "guard/edit/:id",
    component: GuardEditApproveComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "guard/create",
    component: GuardCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "guard/approve",
    component: GuardApproveComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "guard/verify-registration",
    component: GuardVerifyRegistrationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
