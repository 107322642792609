import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Constant } from '../constant';

var jsonResponse: any;
@Injectable({
  providedIn: 'root'
})
export class CodeRedHistoryService {

  httpOptions = {
    headers:new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(private http:HttpClient , private constant : Constant) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    this.httpOptions.headers = headers;
   }
 
  getById(id): Observable<any> {
    return this.http.get<any>(this.constant.API_DOMAIN + "/api/v1/code-red-history/"+id, this.httpOptions);
  }

  getByCodeRedId(codeRedId): Observable<any> {
    return this.http.get<any>(this.constant.API_DOMAIN + "/api/v1/code-red-history/code-red/"+codeRedId, this.httpOptions);
  }

  getByCodeRedIdAndStatusApprove(codeRedId): Observable<any> {
    return this.http.get<any>(this.constant.API_DOMAIN + "/api/v1/code-red-history/code-red/status-approve/"+codeRedId, this.httpOptions);
  }

  search(req): Observable<any> {
    return this.http.post<any>(this.constant.API_DOMAIN + "/api/v1/code-red-history/search", JSON.stringify(req), this.httpOptions);
  }

  updateStatus(req,id): Observable<any> {
    return this.http.put<any>(this.constant.API_DOMAIN + "/api/v1/code-red-history/"+id+"/status", JSON.stringify(req), this.httpOptions);
  }

  delete(req,id): Observable<any> {
    return this.http.patch<any>(`${this.constant.API_DOMAIN}/api/v1/code-red-history/delete/` + id, JSON.stringify(req),
     this.httpOptions
     );
  }

  update(formData: FormData): Observable<HttpEvent<any>> {
    const req = new HttpRequest('POST', `${this.constant.API_REPORT_DOMAIN}/api/v1/back-list/update-code-red-history`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: new HttpHeaders({
        'X-User': 'test'
        })
    });

    return this.http.request(req);
  }

  // Error handling
  handleError(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
    // Get client-side error
    errorMessage = error.error.message;
    } else {
    // Get server-side error
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //window.alert(errorMessage);
    return throwError(errorMessage);
  }
}