import { Component, OnInit } from '@angular/core';
declare var jquery: any;
declare var $: any;
declare var Swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CodeRedHistoryService, CodeRedService, CommonService } from 'src/app/shared';
import { Device, DeviceSummaryInfo } from 'src/app/shared/model/model';
import { DeviceService } from 'src/app/shared/service/device.service';

@Component({
  selector: 'app-guard-edit-approve',
  templateUrl: './guard-edit.component.html',
  styleUrls: ['./guard-edit.component.css']
})
export class GuardEditApproveComponent implements OnInit {

  editForm: FormGroup;
  submitted_edit = false;
  editDetailForm: FormGroup;
  submitted_edit_detail = false;

  //Device Model
  deviceId: Number;
  agencyList?: Array<any> = [];
  deviceList?: Array<any> = []; 
  isDuplicateImei: Boolean = false;
  deviceSummaryInfo: DeviceSummaryInfo = {limit:0, active:0, inactive:0};
  isSelectAll: Boolean = false;
  codeRedId;
  codeRed;
  codeRedHistory;

  optionleDate: any = {
    locale: { format: 'DD-MM-YYYY' },
    alwaysShowCalendars: false,
    singleDatePicker: true
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private router: Router,
    private codeRedService: CodeRedService,
    private codeRedHistoryService: CodeRedHistoryService
  ) {

    this.editForm = fb.group({
      'id': ['', Validators.required],
      'first_name': ['', Validators.required],
      'last_name': ['', Validators.required],
      'identity_no': ['', Validators.required],
      'mobile': [''],
      'email': [''],
      'address_detail': [''],
      'work_history': [''],
      'note': [''],
      'date_incident': [''],
      'informant_name': [''],
      'code_red_id': ['0'],
      'user_id': [''],
      'customer_id': [''],
    });
    this.editForm.controls.identity_no.disable();

    this.editDetailForm = fb.group({
      'id': ['', Validators.required],
      'date_incident': [''],
      'code_red_name': ['', Validators.required],
      'informant_name': ['', Validators.required],
      'location': ['', Validators.required],
      'incidence': ['', Validators.required],
      'code_red_id': ['', Validators.required],
      'user_id': [''],
      'customer_id': [''],
    });
    this.editDetailForm.controls.code_red_name.disable();
    this.editDetailForm.controls.informant_name.disable();
  }

  customerId
  ngOnInit() {
    this.activatedRoute.params.forEach((urlParams) => {
      this.codeRedId = urlParams['id'].replace('#', '');
      console.log("codeRedId : "+this.codeRedId); 
      this.getCodeRed();
      this.getCodeRedHistory();
    });
    this.customerId = localStorage.getItem("customer_id");
  }

  getExtension(filename) {
    var parts = filename.split('.');
    return parts[parts.length - 1];
  }

  isPdfAndImage(filename) {
    var ext = this.getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'jpg':
      case 'jpeg':
      case 'png': 
        return true;
    }
    return false;
  }

  selectedFile0 = []
  selectedFile1 = []
  selectedFile2 = []
  selectedFile3 = []
  selectedFile4 = []
  selectedFile5 = []
  selectedFile6 = []
  selectFile(event, type) {
    if(type === 0){
      this.selectedFile0 = event.target.files;
      var file = event.target.files[0];
      if(!this.isPdfAndImage(file.name)){
        this.warningDialog('รองรับไฟล์รูปภาพเท่านั้น jpg,jpeg,png');
        this.selectedFile0 = null;
        return;
      }
      if (file) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.codeRed.image_url = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    }else if(type === 1){
      this.selectedFile1 = event.target.files;
    }else if(type === 2){
      this.selectedFile2 = event.target.files;
    }else if(type === 3){
      this.selectedFile3 = event.target.files;
    }else if(type === 4){
      this.selectedFile4 = event.target.files;
    }else if(type === 5){
      this.selectedFile5 = event.target.files;
    }else if(type === 6){
      this.selectedFile6 = event.target.files;
    }
    console.log(this.selectedFile0);
  }

  getCodeRedHistory(){
    this.spinner.show();
    this.codeRedHistoryService.getByCodeRedId(this.codeRedId).subscribe(res=>{
      console.log(res);
      this.codeRedHistory = res;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      console.error(error);
      this.failDialog('');
    });
  }

  getCodeRed(){
    this.spinner.show();
    this.codeRedService.getById(this.codeRedId).subscribe(res=>{
      console.log(res);
      this.codeRed = res;

      var userFirstName = localStorage.getItem("user_first_name");
      var userLastName = localStorage.getItem("user_last_name");
      var userId = localStorage.getItem("user_id");
      var customerId = localStorage.getItem("customer_id");
  
      this.editForm.patchValue({
        id: this.codeRedId,
        first_name: res.first_name,
        last_name: res.last_name,
        identity_no: res.identity_no,
        mobile: res.mobile,
        email: res.email,
        address_detail: res.address_detail,
        note: res.note,
        informant_name: userFirstName+" "+userLastName,
        user_id: userId,
        customer_id: customerId,

      });

      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      console.error(error);
      this.failDialog('');
    });
  }

  updateCodeRed(){
    this.submitted_edit = true;
    console.log(this.editForm.value);
    if(this.editForm.invalid){
      return;
    }

    var file0 = new File([""], "");
    if(this.selectedFile0.length > 0){
      file0 = this.selectedFile0[0];
    }

    var formData: FormData = new FormData();
    formData.append('file0', file0);
    formData.append('codeRedId', this.editForm.value.id);
    formData.append('firstName', this.editForm.value.first_name);
    formData.append('lastName', this.editForm.value.last_name);
    formData.append('identityNo', this.codeRed.identity_no);
    formData.append('mobile', this.editForm.value.mobile);
    formData.append('email', this.editForm.value.email);
    formData.append('addressDetail', this.editForm.value.address_detail);
    formData.append('note', this.editForm.value.note);
    formData.append('informantName', this.editForm.value.informant_name);
    formData.append('userId', this.editForm.value.user_id);
    formData.append('customerId', this.editForm.value.customer_id);

    this.spinner.show();
    this.codeRedService.update(formData).subscribe(data=>{
      console.log(data);
      if(data.type == 1){
        this.spinner.hide();
        this.successDialog();
        this.getCodeRed();
      }
    }, error => {
      this.spinner.hide();
      console.error(error);
      this.failDialog(error);
    });
  }

  currentStatus
  currentId;
  delete(id,status){
    this.note = '';
    this.currentId = id;
    this.currentStatus = status;
    $('#modal-remove').modal('show');
  }

  note;
  deleteProcess(){
    var status = "DELETE";
    if(this.currentStatus == "AP"){
      status = "CANCEL";
    }
    if(this.currentStatus == "AP" && !this.note){
      this.warningDialog('กรุณาใส่เหตุผลในการลบข้อมูล');
      return;
    }
    var req = {
      note: this.note,
      status: status
    }
    this.spinner.show();
    console.log(this.currentId);
    this.codeRedHistoryService.delete(req,this.currentId).subscribe(data => {
      this.spinner.hide();
      $('#modal-remove').modal('hide');
      this.successDialog();
      this.getCodeRedHistory();
    }, error => {
      this.spinner.hide();
      console.error(error);
      this.failDialog('');
    });
  }

  data = {}
  openDetail(data){
    this.data = data;
    $('#modal-detail').modal('show');
  }

  dateIncident = this.getDateStr(new Date());
  selectedDateIncident(value: any, datepicker?: any) {
    console.log(value);
    this.dateIncident = this.getDateStr(value.end._d);
    console.log(this.dateIncident);
    this.editDetailForm.patchValue({
      date_incident: this.dateIncident,
    });
  }

  getDateStr(date){
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();
    return dd + '-' + mm + '-' + yyyy;
  }

  openEdit(data){
    this.submitted_edit_detail = false;
    this.data = data;

    var userId = localStorage.getItem("user_id");
    var customerId = localStorage.getItem("customer_id");
    this.dateIncident = this.getDateStr(new Date(data.date_incident));

    this.editDetailForm.patchValue({
      id: data.id,
      code_red_id: this.codeRedId,
      code_red_name: data.code_red_name,
      date_incident: this.dateIncident,
      informant_name: data.informant_name,
      location: data.location,
      incidence: data.incidence,
      user_id: userId,
      customer_id: customerId,
    });

    $('#modal-edit').modal('show');
  }

  updateCodeRedHistory(){
    this.submitted_edit_detail = true;
    console.log(this.editDetailForm.value);
    if(this.editDetailForm.invalid){
      return;
    }

    var file1 = new File([""], "");
    if(this.selectedFile1.length > 0){
      file1 = this.selectedFile1[0];
    }
    var file2 = new File([""], "");
    if(this.selectedFile2.length > 0){
      file2 = this.selectedFile2[0];
    }
    var file3 = new File([""], "");
    if(this.selectedFile3.length > 0){
      file3 = this.selectedFile3[0];
    }
    var file4 = new File([""], "");
    if(this.selectedFile4.length > 0){
      file4 = this.selectedFile4[0];
    }
    var file5 = new File([""], "");
    if(this.selectedFile5.length > 0){
      file5 = this.selectedFile5[0];
    }
    var file6 = new File([""], "");
    if(this.selectedFile6.length > 0){
      file6 = this.selectedFile6[0];
    }

    var formData: FormData = new FormData();
    formData.append('file1', file1);
    formData.append('file2', file2);
    formData.append('file3', file3);
    formData.append('file4', file4);
    formData.append('file5', file5);
    formData.append('file6', file6);
    formData.append('id',  this.editDetailForm.value.id);
    formData.append('identityNo', this.codeRed.identity_no);
    formData.append('dateIncident', this.editDetailForm.value.date_incident);
    formData.append('location', this.editDetailForm.value.location);
    formData.append('incidence', this.editDetailForm.value.incidence);
    formData.append('userId', this.editDetailForm.value.user_id);
    formData.append('customerId', this.editDetailForm.value.customer_id);

    this.spinner.show();
    this.codeRedHistoryService.update(formData).subscribe(data=>{
      console.log(data);
      if(data.type == 1){
        this.spinner.hide();
        this.successDialog();
        this.getCodeRedHistory();
        $('#modal-edit').modal('hide');
      }
    }, error => {
      this.spinner.hide();
      console.error(error);
      this.failDialog(error);
    });
  }

  openDialogApprove(id){
    Swal.fire({
      title: 'ยืนยันอนุมัติ',
      text: "กดปุ่มยืนยันเพื่ออนุมัติ",
      type: 'warning',
      showCancelButton: true,
      // confirmButtonColor: '#3085d6',
      // cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ปิด'
    }).then((result) => {
      console.log(result)
      if (result.value) {
        this.spinner.show()
        var req = {
          "status":"AP"
        }
        this.codeRedHistoryService.updateStatus(req,id).subscribe(resp => {
          this.spinner.hide();
          $('#modal-detail').modal('hide');
          this.successDialog();
          setTimeout(() => {
            this.router.navigate(['/guard/approve']);
          }, 1000);
        }, err => {
          this.spinner.hide()
          this.failDialog('')
          console.log("=== err ===")
          console.log(err)
        });

      }
    })
  }

  openDialogNotApprove(id){
    $('#modal-detail').modal('hide');
    Swal.fire({
      title: 'หมายเหตุในการไม่อนุมัติ',
      input: 'text',
      showCancelButton: true,
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ปิด',
      showLoaderOnConfirm: true,
      preConfirm: (text) => {
        if(!text){
          Swal.showValidationMessage(
            `หมายเหตุในการไม่อนุมัติ`
          )
          return
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      console.log(result)
      if (result.value) {
        this.spinner.show();
        var req = {
          "note":result.value,
          "status":"NAP"
        }
        this.codeRedHistoryService.updateStatus(req,id).subscribe(resp => {
          console.log(resp)
          this.spinner.hide();
          this.successDialog();
          setTimeout(() => {
            this.router.navigate(['/guard/approve']);
          }, 1000);
        }, err => {
          this.spinner.hide();
          this.failDialog('')
          console.log("=== err ===")
          console.log(err)
        });
      }
    })
  }

  successDialog() {
    this.spinner.hide();
    Swal.fire("ทำรายการสำเร็จ!", "", "success");
  }

  warningDialog(msg){
    Swal.fire({
      type: 'warning',
      title: '',
      text: msg
    })
  }

  failDialog(msg) { 
    this.spinner.hide();
    Swal.fire({
      type: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: msg
    })
  }

}
