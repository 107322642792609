import { Component, OnInit } from '@angular/core';
declare var jquery: any;
declare var $: any;
declare var Swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/shared';

@Component({
  selector: 'app-guard-verify-registration',
  templateUrl: './guard-verify-registration.component.html',
  styleUrls: ['./guard-verify-registration.component.css']
})
export class GuardVerifyRegistrationComponent implements OnInit {


  customerList?: Array<any> = []; 
  addForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
  ) {
    this.addForm = fb.group({
      'status': ['', Validators.required],
      'note': [''],
    });
  }

  firstName
  lastName
  identityNo
  ngOnInit() {
     this.search();
  }

  customerId
  openModal(data){
    this.customerId = data.id;
    this.addForm.patchValue({
      status: "",
      note: ""
    });
    $('#modal-approve').modal('show');
  }

  updateStatus(){
    this.spinner.show();
    this.authService.updateStatus(this.addForm.value,this.customerId).subscribe(res=>{
      console.log(res)
      $('#modal-approve').modal('hide');
      setTimeout(() => {
        this.spinner.hide();
        this.search();
      }, 100);
    }, error => {
      this.spinner.hide();
      console.error(error);
      this.failDialog('');
    });
  }

  search(){
    this.spinner.show();
    $("#customer_table").DataTable().clear().destroy();
    this.customerList = [];
    this.authService.getCustomer().subscribe(res=>{
      console.log(res)
      this.customerList = res;
      setTimeout(() => {
        this.spinner.hide();
        $('#customer_table').DataTable({
        });
      }, 100);
    }, error => {
      this.spinner.hide();
      console.error(error);
      this.failDialog('');
    });
  }

  successDialog() {
    this.spinner.hide();
    Swal.fire("ทำรายการสำเร็จ!", "", "success");
  }

  failDialog(msg) { 
    this.spinner.hide();
    Swal.fire({
      type: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: msg
    })
  }

}
