import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../shared/service/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log('=== canActivate ===');
        var accessToken = localStorage.getItem("access_token");
        if(!accessToken){
            localStorage.clear();
            // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
        this.authService.getAuthen().subscribe(response => {
          console.log('auth guards');
          // console.log(response);
        }, error => {
          console.error(error);
          if(error.error.code == "token_expired"){
            localStorage.clear();
            // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
          }
          return false;
        });
        return true;

        // not logged in so redirect to login page with the return url
        //this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        //return false;
    }
}