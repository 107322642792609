import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { OrgChartModule } from 'ng2-org-chart';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { GuardCreateComponent } from './guard-create.component';
import { Daterangepicker } from 'ng2-daterangepicker';

@NgModule({
  imports: [
    CommonModule,
    Daterangepicker,
    ReactiveFormsModule,
    FormsModule,
    OrgChartModule,
    NgxSpinnerModule
  ],
  declarations: [GuardCreateComponent],
  exports: [
    GuardCreateComponent
  ],
  providers: [
    NgxSpinnerService
  ]
})
export class GuardCreateModule { }
