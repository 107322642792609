import { Component, OnInit } from '@angular/core';
declare var jquery: any;
declare var $: any;
declare var Swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { CodeRedHistoryService, CodeRedService, CommonService } from 'src/app/shared';
import { Device, DeviceSummaryInfo } from 'src/app/shared/model/model';
import { DeviceService } from 'src/app/shared/service/device.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-guard-detail',
  templateUrl: './guard-detail.component.html',
  styleUrls: ['./guard-detail.component.css']
})
export class GuardDetailComponent implements OnInit {

  searchForm: FormGroup;
  addForm: FormGroup;
  editForm: FormGroup;
  submitted_add = false;
  submitted_edit = false;
  submitted_search = false;

  //Device Model
  deviceId: Number;
  agencyList?: Array<any> = [];
  deviceList?: Array<any> = []; 
  isDuplicateImei: Boolean = false;
  deviceSummaryInfo: DeviceSummaryInfo = {limit:0, active:0, inactive:0};
  isSelectAll: Boolean = false;
  id;
  codeRed;
  codeRedHistory;

  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private router: Router,
    private commonService: CommonService,
    private codeRedService: CodeRedService,
    private codeRedHistoryService: CodeRedHistoryService
  ) {
    this.searchForm = fb.group({
       
    });
    this.addForm = fb.group({
      'agency_id': ['', Validators.required],
      'name': ['', Validators.required],
      'imei': ['', Validators.required],
      'description': [''],
      'is_active': [true]
    });
    this.editForm = fb.group({
      'id': ['', Validators.required],
      'agency_id': ['', Validators.required],
      'name': ['', Validators.required],
      'imei': ['', Validators.required],
      'description': [''],
      'is_active': [true]
    });
  }

  ngOnInit() {
    this.activatedRoute.params.forEach((urlParams) => {
      this.id = urlParams['id'].replace('#', '');
      console.log("id : "+this.id); 
      this.spinner.show();
      this.getCodeRed();
      this.getCodeRedHistory();
    });
  }

  getCodeRed(){
    this.codeRedService.getById(this.id).subscribe(res=>{
      console.log(res);
      this.codeRed = res;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      console.error(error);
      this.failDialog('');
    });
  }

  getCodeRedHistory(){
    this.codeRedHistoryService.getByCodeRedIdAndStatusApprove(this.id).subscribe(res=>{
      console.log(res);
      this.codeRedHistory = res;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      console.error(error);
      this.failDialog('');
    });
  }

  data = {}
  openDetail(data){
    this.data = data;
    $('#modal-detail').modal('show');
  }

  successDialog() {
    this.spinner.hide();
    Swal.fire("ทำรายการสำเร็จ!", "", "success");
  }

  failDialog(msg) { 
    this.spinner.hide();
    Swal.fire({
      type: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: msg
    })
  }

}
