import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { OrgChartModule } from 'ng2-org-chart';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { GuardDetailComponent } from './guard-detail.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    OrgChartModule,
    NgxSpinnerModule
  ],
  declarations: [GuardDetailComponent],
  exports: [
    GuardDetailComponent
  ],
  providers: [
    NgxSpinnerService
  ]
})
export class GuardDetailModule { }
